<template>
  <v-dialog v-model="dialog" max-width="800" persistent>
    <v-card>
      <v-card-title>
        <span class="headline">Request Access</span>
      </v-card-title>
      <v-card-text>
        <v-row>
          <v-col cols="12" sm="12" md="6" lg="6">
            <v-row>
              <v-col cols="4" sm="4" md="4" lg="4" class="py-0">
                <v-subheader>Type</v-subheader>
              </v-col>
              <v-col cols="8" sm="8" md="8" lg="8" class="py-0">
                <v-select
                  flat
                  dense
                  outlined
                  background-color="white"
                  :items="types"
                  v-model="item.type"
                ></v-select>
              </v-col>
            </v-row>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12" sm="12" md="6" lg="6">
            <v-row>
              <v-col cols="4" sm="4" md="4" lg="4" class="py-0">
                <v-subheader>To</v-subheader>
              </v-col>
              <v-col cols="8" sm="8" md="8" lg="8" class="py-0">
                <v-select
                  flat
                  dense
                  outlined
                  background-color="white"
                  item-text="name"
                  item-value="id"
                  :items="managers"
                  v-model="item.to"
                ></v-select>
              </v-col>
            </v-row>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12" sm="12" md="6" lg="6">
            <v-row>
              <v-col cols="4" sm="4" md="4" lg="4" class="py-0">
                <v-subheader>Remark</v-subheader>
              </v-col>
              <v-col cols="8" sm="8" md="8" lg="8" class="py-0">
                <v-textarea
                  outlined
                  flat
                  dense
                  background-color="white"
                  rows="5"
                  v-model="item.remark"
                ></v-textarea>
              </v-col>
            </v-row>
          </v-col>
        </v-row>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="success" text @click="send">
          Send
        </v-btn>
        <v-btn color="primary" @click="cancel">
          Cancel
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapState } from "vuex";
export default {
  name: "dialog-request",
  props: {
    dialog: Boolean,
    id: Number,
    type: String,
  },
  data() {
    return {
      types: ["Edit", "Delete"],
      item: {
        type: "Edit",
        remark: "",
        from: null,
        to: null,
        transactionId: null,
        transactionType: "",
      },
      defaultItem: {
        type: "Edit",
        remark: "",
        from: null,
        to: null,
        transactionId: null,
        transactionType: "",
      },
    };
  },
  computed: {
    ...mapState("user", ["managers"]),
    ...mapState("auth", ["userDetail"]),
  },
  methods: {
    async send() {
      this.item.transactionId = this.id;
      this.item.transactionType = this.type;
      this.item.from = this.userDetail.id;

      let url = "";
      if (this.type === "Chart Of Account") {
        url = `/general-ledger/chart-of-accounts/${this.item.transactionId}`;
      }

      const response = await this.$store.dispatch("user/requestAccess", this.item);
      if (response.status === 200) {
        const msg = {
          name: this.item.remark,
          type: this.type,
          url: url,
          assignTo: this.item.to,
          from: this.item.from,
        };
        this.$store.dispatch("send", { url: `/ws/notification/${msg.assignTo}`, msg: msg });
        this.$emit("reload");
        this.$emit("close");
      }
    },
    cancel() {
      this.$emit("close");
    },
  },
  mounted() {
    this.$store.dispatch("user/getManager");
    this.item = Object.assign({}, this.defaultItem);
  },
};
</script>

<style></style>
