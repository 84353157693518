<template>
  <form>
    <v-row class="py-0">
      <v-col class="py-0" cols="12" sm="12" md="11" v-if="edit">
        <v-row>
          <v-col cols="4" sm="4" md="3" lg="3" class="py-0">
            <v-subheader>Account Number</v-subheader>
          </v-col>
          <v-col cols="8" sm="8" md="9" lg="9" class="py-0" align-self="center">
            <span>: {{ form.accountNumber }}</span>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="4" sm="4" md="3" lg="3" class="py-0">
            <v-subheader>Account Name</v-subheader>
          </v-col>
          <v-col cols="8" sm="8" md="9" lg="9" class="py-0" align-self="center">
            <span>: {{ form.accountName }}</span>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="4" sm="4" md="3" lg="3" class="py-0">
            <v-subheader>Nature</v-subheader>
          </v-col>
          <v-col cols="8" sm="8" md="9" lg="9" class="py-0" align-self="center">
            <span>: {{ form.nature }}</span>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="4" sm="4" md="3" lg="3" class="py-0">
            <v-subheader>Currency</v-subheader>
          </v-col>
          <v-col cols="8" sm="8" md="9" lg="9" class="py-0" align-self="center">
            <span
              >:
              {{ textCurrency }}
            </span>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="4" sm="4" md="3" lg="3" class="py-0">
            <v-subheader>Balance</v-subheader>
          </v-col>
          <v-col cols="8" sm="8" md="9" lg="9" class="py-0" align-self="center">
            <span
              >:
              {{ form.symbol }}
              {{ formatPrice(form.nominal) }}</span
            >
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="4" sm="4" md="3" lg="3" class="py-0">
            <v-subheader>Transaction</v-subheader>
          </v-col>
          <v-col cols="8" sm="8" md="9" lg="9" class="py-0" align-self="center">
            <span>: {{ form.transition ? "YES" : "NO" }}</span>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="4" sm="4" md="3" lg="3" class="py-0">
            <v-subheader>Type</v-subheader>
          </v-col>
          <v-col cols="8" sm="8" md="9" lg="9" class="py-0" align-self="center">
            <span>: {{ form.type }}</span>
          </v-col>
        </v-row>
      </v-col>
      <v-col class="py-0" cols="12" sm="12" md="11" v-if="!edit">
        <v-card flat>
          <v-row>
            <v-col cols="4" sm="4" md="2" lg="2" class="py-0">
              <v-checkbox v-model="checkbox" label="Sub Account"></v-checkbox>
            </v-col>
            <v-col cols="8" sm="8" md="4" lg="4" v-if="checkbox" class="py-0">
              <v-autocomplete
                v-model="form.pointerId"
                :items="dropDownChartOfAccount"
                outlined
                flat
                dense
                item-value="accountId"
                item-text="accountName"
                background-color="white"
                :filter="filterObject"
              >
                <template v-slot:item="data">
                  <v-list-item-content>
                    <v-list-item-title v-html="data.item.accountName"></v-list-item-title>
                    <v-list-item-subtitle v-html="data.item.accountNumber"></v-list-item-subtitle>
                  </v-list-item-content>
                </template>
              </v-autocomplete>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="4" sm="4" md="2" lg="2" class="py-0">
              <v-subheader>Account Number <span class="red--text">*</span></v-subheader>
            </v-col>
            <v-col cols="8" sm="8" md="4" lg="4" class="py-0">
              <v-text-field
                v-model="form.accountNumber"
                outlined
                flat
                dense
                background-color="white"
              ></v-text-field>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="4" sm="4" md="2" lg="2" class="py-0">
              <v-subheader>Account Name <span class="red--text">*</span></v-subheader>
            </v-col>
            <v-col cols="8" sm="8" md="4" lg="4" class="py-0">
              <v-text-field
                v-model="form.accountName"
                outlined
                flat
                dense
                background-color="white"
              ></v-text-field>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="4" sm="4" md="2" lg="2" class="py-0">
              <v-subheader>Nature <span class="red--text">*</span></v-subheader>
            </v-col>
            <v-col cols="8" sm="8" md="4" lg="4" class="py-0">
              <v-select
                v-model="form.nature"
                :items="listNature"
                outlined
                flat
                dense
                background-color="white"
              ></v-select>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="4" sm="4" md="2" lg="2" class="py-0">
              <v-subheader>Currency <span class="red--text">*</span></v-subheader>
            </v-col>
            <v-col cols="8" sm="8" md="4" lg="4" class="py-0">
              <v-select
                v-model="form.currencyId"
                :items="dropDownCurrency"
                outlined
                flat
                dense
                background-color="white"
              ></v-select>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="4" sm="4" md="2" lg="2" class="py-0">
              <v-subheader>Balance</v-subheader>
            </v-col>
            <v-col cols="8" sm="8" md="4" lg="4" class="py-0">
              <v-text-field
                v-model="form.nominal"
                outlined
                flat
                dense
                background-color="white"
                :prefix="form.symbol"
              ></v-text-field>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="4" sm="4" md="2" lg="2" class="py-0">
              <v-subheader>Balance Date</v-subheader>
            </v-col>
            <v-col cols="8" sm="8" md="4" lg="4" class="py-0">
              <v-menu
                v-model="menuStart"
                :close-on-content-click="false"
                :nudge-right="40"
                transition="scale-transition"
                offset-y
                min-width="290px"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                    outlined
                    flat
                    dense
                    background-color="white"
                    v-model="form.openingDate"
                    append-icon="mdi-calendar"
                    readonly
                    v-bind="attrs"
                    v-on="on"
                  ></v-text-field>
                </template>
                <v-date-picker
                  v-model="form.openingDate"
                  @input="menuStart = false"
                ></v-date-picker>
              </v-menu>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="4" sm="4" md="2" lg="2" class="py-0">
              <v-subheader>Vendor</v-subheader>
            </v-col>
            <v-col cols="8" sm="8" md="4" lg="4" class="py-0">
              <v-autocomplete
                flat
                dense
                outlined
                background-color="white"
                v-model="form.vendorId"
                item-text="supplier_name"
                item-value="id"
                :items="vendors"
              ></v-autocomplete>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="4" sm="4" md="2" lg="2" class="py-0">
              <v-subheader>Customer</v-subheader>
            </v-col>
            <v-col cols="8" sm="8" md="4" lg="4" class="py-0">
              <v-autocomplete
                flat
                dense
                outlined
                background-color="white"
                v-model="form.customerId"
                item-text="name"
                item-value="id"
                :items="customers"
              ></v-autocomplete>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="4" sm="4" md="2" lg="2" class="py-0">
              <v-subheader>Transaction</v-subheader>
            </v-col>
            <v-col cols="8" sm="8" md="4" lg="4" class="py-0">
              <v-radio-group v-model="form.transaction" row>
                <v-radio label="YES" :value="true"></v-radio>
                <v-radio label="NO" :value="false"></v-radio>
              </v-radio-group>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="4" sm="4" md="2" lg="2" class="py-0">
              <v-subheader>Type</v-subheader>
            </v-col>
            <v-col cols="8" sm="8" md="4" lg="4" class="py-0">
              <v-text-field
                flat
                dense
                outlined
                background-color="white"
                v-model="form.type"
              ></v-text-field>
            </v-col>
          </v-row>
        </v-card>
      </v-col>
      <v-col cols="12" sm="12" md="1">
        <!-- Button for approved by MANAGER -->
        <v-row class="py-0 mb-2" v-if="checkRole && form.request">
          <v-col cols="12" class="py-0">
            <v-tooltip bottom>
              <template v-slot:activator="{ on }">
                <v-btn color="success" v-on="on" @click="approved">
                  <v-icon>
                    mdi-checkbox-marked-outline
                  </v-icon>
                </v-btn>
              </template>
              <span>Approved</span>
            </v-tooltip>
          </v-col>
        </v-row>
        <v-row class="py-0 mb-2" v-if="checkRole && form.request">
          <v-col cols="12" class="py-0">
            <v-tooltip bottom>
              <template v-slot:activator="{ on }">
                <v-btn color="error" v-on="on" @click="notApproved">
                  <v-icon>
                    mdi-close-box-outline
                  </v-icon>
                </v-btn>
              </template>
              <span>Not Approved</span>
            </v-tooltip>
          </v-col>
        </v-row>
        <!-- Button for all role -->
        <!-- <v-row class="py-0 mb-2" v-if="!form.request">
          <v-col cols="12" class="py-0">
            <v-tooltip bottom>
              <template v-slot:activator="{ on }">
                <v-btn color="primary" v-on="on" @click="request">
                  <v-icon>
                    mdi-clock
                  </v-icon>
                </v-btn>
              </template>
              <span>Request</span>
            </v-tooltip>
          </v-col>
        </v-row> -->
        <v-row class="py-0 mb-2" v-if="!edit">
          <v-col cols="12" class="py-0">
            <v-tooltip bottom>
              <template v-slot:activator="{ on }">
                <v-btn color="success" v-on="on" @click="updateChartOfAccount">
                  <v-icon>
                    mdi-content-save
                  </v-icon>
                </v-btn>
              </template>
              <span>Save</span>
            </v-tooltip>
          </v-col>
        </v-row>
        <v-row class="py-0 mb-2" v-if="edit && userDetail.role === 'ROLE_ADMIN'">
          <v-col cols="12" class="py-0">
            <v-tooltip bottom>
              <template v-slot:activator="{ on }">
                <v-btn color="success" v-on="on" @click="editForm">
                  <v-icon>
                    mdi-pencil
                  </v-icon>
                </v-btn>
              </template>
              <span>Edit</span>
            </v-tooltip>
          </v-col>
        </v-row>
        <!-- <v-row class="py-0 mb-2" v-if="edit && form.deletable">
          <v-col cols="12" class="py-0">
            <v-tooltip bottom>
              <template v-slot:activator="{ on }">
                <v-btn color="error" v-on="on" @click="deleteChartOfAccount">
                  <v-icon>
                    mdi-delete
                  </v-icon>
                </v-btn>
              </template>
              <span>Delete</span>
            </v-tooltip>
          </v-col>
        </v-row> -->
        <v-row class="py-0 mb-2" v-if="!edit">
          <v-col cols="12" class="py-0">
            <v-tooltip bottom>
              <template v-slot:activator="{ on }">
                <v-btn color="error" v-on="on" @click="cancel">
                  <v-icon>
                    mdi-close
                  </v-icon>
                </v-btn>
              </template>
              <span>Close</span>
            </v-tooltip>
          </v-col>
        </v-row>
        <v-row class="py-0 mb-2" v-if="edit">
          <v-col cols="12" class="py-0">
            <v-tooltip bottom>
              <template v-slot:activator="{ on }">
                <v-btn color="primary" v-on="on" @click="back">
                  <v-icon>
                    mdi-arrow-left
                  </v-icon>
                </v-btn>
              </template>
              <span>Back</span>
            </v-tooltip>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
    <dialog-request
      :dialog="dialogRequest"
      :id="id"
      @close="close"
      @reload="getChartOfAccountDetail"
      type="Chart Of Account"
    ></dialog-request>
  </form>
</template>

<script>
import { mapState } from "vuex";
import DialogRequest from "../../../components/DialogRequest.vue";

export default {
  name: "chart-of-account-detail",
  props: {
    id: {
      type: Number,
      default: () => undefined,
    },
  },
  components: {
    "dialog-request": DialogRequest,
  },
  data: () => ({
    edit: true,
    listNature: ["Debit", "Credit"],
    checkbox: false,
    menuStart: false,
    dialogRequest: false,
    form: {},
  }),
  computed: {
    ...mapState("currency", ["dropDownCurrency"]),
    ...mapState("auth", ["checkRole", "userDetail"]),
    ...mapState("external", ["vendors", "customers"]),
    textCurrency() {
      const currency = this.dropDownCurrency.find(element => element.value == this.form.currencyId);
      return currency ? currency.text : "";
    },
    setCheckBox() {
      if (this.form.pointerId !== null && this.form.pointerId > 0) {
        return true;
      }
      return false;
    },
  },
  watch: {
    setCheckBox(val) {
      this.checkbox = val;
    },
  },
  methods: {
    async getChartOfAccountDetail() {
      await this.$store.dispatch("chartOfAccount/getById", this.id).then(response => {
        this.form = response.data;
      });
    },
    async updateChartOfAccount() {
      this.$store.commit("SET_LOADER", true);
      await this.$store
        .dispatch("chartOfAccount/update", this.form)
        .then(() => this.$store.commit("SET_LOADER", false))
        .catch(() => {
          this.$store.dispatch("toast", {
            type: "error",
            message: "Ada masalah dengan aplikasi, mohon hubungi admin aplikasi",
          });
          this.$store.commit("SET_LOADER", false);
        });
    },
    async deleteChartOfAccount() {
      this.$store.commit("SET_LOADER", true);
      await this.$store
        .dispatch("chartOfAccount/delete", this.id)
        .then(() => this.$store.commit("SET_LOADER", false))
        .catch(() => {
          this.$store.dispatch("toast", {
            type: "error",
            message: "Ada masalah dengan aplikasi, mohon hubungi admin aplikasi",
          });
          this.$store.commit("SET_LOADER", false);
        });
    },
    async getDropDownCurrency() {
      await this.$store.dispatch("currency/getDropDownCurrency");
    },
    async getDropDownChartOfAccount() {
      const response = await this.$store.dispatch("chartOfAccount/getAccounts");
      if (response.status === 200) {
        this.dropDownChartOfAccount = response.data;
      }
    },
    back() {
      this.$router.go(-1);
    },
    editForm() {
      this.edit = false;
    },
    cancel() {
      this.edit = true;
    },
    formatPrice(value) {
      let val = (value / 1).toFixed(2).replace(".", ",");
      return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".");
    },
    approved() {
      this.$store.dispatch("chartOfAccount/approval", { id: this.id, approved: true });
      this.getChartOfAccountDetail();
    },
    notApproved() {
      this.$store.dispatch("chartOfAccount/approval", { id: this.id, approved: false });
      this.getChartOfAccountDetail();
    },
    request() {
      this.dialogRequest = true;
    },
    close() {
      this.dialogRequest = false;
    },
    // eslint-disable-next-line no-unused-vars
    filterObject(item, queryText, itemText) {
      return (
        item.accountName.toLocaleLowerCase().indexOf(queryText.toLocaleLowerCase()) > -1 ||
        item.accountNumber.toLocaleLowerCase().indexOf(queryText.toLocaleLowerCase()) > -1
      );
    },
    async getInitialData() {
      this.$store.commit("SET_LOADER", true);
      Promise.all([
        this.getDropDownChartOfAccount(),
        this.getDropDownCurrency(),
        this.getChartOfAccountDetail(),
        await this.$store.dispatch("external/getVendors"),
        await this.$store.dispatch("external/getCustomers"),
      ])
        .then(() => this.$store.commit("SET_LOADER", false))
        .catch(() => {
          this.$store.dispatch("toast", {
            type: "error",
            message: "Ada masalah dengan aplikasi, mohon hubungi admin aplikasi",
          });
          this.$store.commit("SET_LOADER", false);
        });
    },
  },
  mounted() {
    this.getInitialData();
  },
};
</script>

<style></style>
